<template>
  <div class="wrapper">
    <div class="prompt">
      <div class="title">温馨提示</div>
      <div class="text">
        请妥善保管卡号卡密，切不可将券码信息交给其他人，谨防诈骗!
      </div>
    </div>

    <div
      class="detail"
      v-for="item in detail.outStorageDetailsVOList"
      :key="item.id"
    >
      <div class="info">
        <div class="title">{{ item.gcName || '-' }}</div>
        <div class="desc">
          商品有效期：{{
            common.formatDate(item.virtualEndTime, '{yyyy}-{mm}-{dd}')
          }}
        </div>
      </div>
      <div class="list">
        <div class="item" v-if="[0, 2, 3].includes(item.gcShape)">
          <div class="label">卡号：</div>
          <div class="content">
            <div class="text">{{ item.cardNo }}</div>
            <div
              class="button"
              v-clipboard:copy="item.cardNo"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </div>
        <div class="item" v-if="[0, 1].includes(item.gcShape)">
          <div class="label">卡密：</div>
          <div class="content">
            <div class="text">{{ item.cardPwd }}</div>
            <div
              class="button"
              v-clipboard:copy="item.cardPwd"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </div>
        <div class="item" v-if="[2, 3].includes(item.gcShape)">
          <div class="label">短链：</div>
          <div class="content">
            <div class="text">{{ item.shortUrl }}</div>
            <div
              class="button"
              v-clipboard:copy="item.shortUrl"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </div>
        <div class="item" v-if="[3].includes(item.gcShape)">
          <div class="label">验证码：</div>
          <div class="content">
            <div class="text">{{ item.verifyCode }}</div>
            <div
              class="button"
              v-clipboard:copy="item.verifyCode"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="prompt desc" v-if="detail.describe">
      <div class="title">商品使用说明</div>
      <div class="text" v-html="detail.describe"></div>
    </div>
  </div>
</template>

<script>
import { listRedeemCode } from '@/api/SyncLink'
export default {
  data() {
    return {
      detail: {
        outStorageDetailsVOList: []
      }
    }
  },
  mounted() {
    this.loadDetail()
  },
  methods: {
    loadDetail() {
      this.$toast.loading('加载中...')
      const { redeCodeNo, token } = this.$route.query
      listRedeemCode({ redeCodeNo, token }).then(res => {
        if (res.code !== 0) {
          this.$toast(res.message)
          return
        }
        this.detail = res.data
        this.$toast.clear()
      })
    },
    copySuccess() {
      this.$toast({
        message: '复制成功'
      })
    },
    copyError() {
      this.$toast({
        message: '复制失败'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  height: 100%;
  min-height: 100vh;
  background-color: #ffdcab;
  padding: 30px;
  .prompt {
    min-height: 283px;
    border-radius: 30px;
    padding: 35px;
    background-color: #fff;
    .title {
      width: 297px;
      height: 70px;
      font-size: 40px;
      font-weight: bold;
      color: #371500;
      text-align: center;
      line-height: 70px;
      font-family: Source Han Sans CN;
      margin: 0 auto;
      background-image: url('~@/assets/img/ECoating/title_bg.png');
      background-size: 100% 100%;
    }
    .text {
      margin-top: 48px;
      font-size: 26px;
      color: #371500;
      font-weight: 400;
      line-height: 44px;
    }
  }
  .detail {
    margin-top: 30px;
    border-radius: 30px;
    padding: 30px 30px 71px;
    background-color: #fff;
    .info {
      position: relative;
      line-height: 1;
      padding-bottom: 42px;
      border-bottom: 1px dashed #dcdcdc;
      &::after {
        position: absolute;
        content: '';
        right: -41.5px;
        bottom: -11.5px;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: #ffdcab;
      }
      &::before {
        position: absolute;
        content: '';
        left: -41.5px;
        bottom: -11.5px;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: #ffdcab;
      }
      .title {
        font-size: 36px;
        color: #371500;
        margin-top: 9px;
      }
      .desc {
        font-size: 24px;
        color: #999;
        margin-top: 18px;
      }
    }
    .list {
      .item {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        align-items: center;
        margin-top: 65px;
        &:first-child {
          margin-top: 41px;
        }
        .label {
          font-size: 24px;
          color: #999999;
        }
        .content {
          display: flex;
          align-items: center;
          .text {
            font-size: 24px;
            color: #202638;
            font-weight: bold;
            font-family: OPPOSans;
          }
          .button {
            font-size: 24px;
            color: #ed471f;
            text-align: center;
            // height: 36px;
            display: block;
            // line-height: 36px;
            border-radius: 18px;
            padding: 5px 12px;
            margin-left: 21px;
            border: 1px solid #ed471f;
          }
        }
      }
    }
  }
  .desc {
    margin-top: 30px;
  }
}
</style>
